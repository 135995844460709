<template>
  <div class="firstBox">
    <div class="itembox">
      <div class="title">appKey：</div>
      <div class="content">
        <el-input placeholder="请填写appKey" :spellcheck="false" v-model="baiduPay.appKey"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div>
    <div class="itembox">
      <div class="title">dealId：</div>
      <div class="content">
        <el-input placeholder="请填写dealId" :spellcheck="false" v-model="baiduPay.dealId"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div>
    <div class="itembox">
      <div class="title">rsaPrivateKeyFilePath：</div>
      <div class="content">
        <!-- v-else <div v-if="islook == 1">{{ baiduPay.apiclient_cert }}</div> -->
        <div class="upBox">
          <el-input placeholder="请上传rsaPrivateKeyFilePath证书" disabled v-model="baiduPay.rsaPrivateKeyFilePath"> </el-input>
          <!-- <p @click="lookexamplesFlag" class="lookJc">查看设置教程</p> -->
          <div class="uploadFile">
            上传文件
            <input type="file" ref="inputer0" @change="getFile(0)" />
          </div>
        </div>
      </div>
    </div>
    <div class="itembox">
      <div class="title">rsaPublicKeyFilePath：</div>
      <div class="content">
        <!--v-else <div v-if="islook == 1">{{ baiduPay.apiclient_key }}</div> -->
        <div class="upBox">
          <el-input placeholder="请上传rsaPublicKeyFilePath证书" disabled v-model="baiduPay.rsaPublicKeyFilePath"> </el-input>
          <div class="uploadFile">
            上传文件
            <input type="file" ref="inputer1" @change="getFile(1)" />
          </div>
        </div>
      </div>
    </div>
    <!-- <LookExamples ref="LookExamples" v-show="lookexamples" @hidden="hiddenexamples"></LookExamples> -->
  </div>
</template>

<script>
// import LookExamples from '@/components/previewImg'
export default {
  // props: {
  //   islook: {
  //     type: Number,
  //     default: 0
  //   }
  // },
  components: {
    // LookExamples
  },
  data() {
    return {
      baiduPay: {
        appKey: '',
        dealId: '',
        rsaPrivateKeyFilePath: '',
        rsaPublicKeyFilePath: ''
      },
      id: -1,
      isHave: true
      // lookexamples: false
    }
  },
  methods: {
    // lookexamplesFlag() {
    //   this.lookexamples = true
    //   this.$refs.LookExamples.getImgList(15)
    // },
    // hiddenexamples() {
    //   this.lookexamples = false
    // },
    // 上传文件
    getFile(status) {
      let files = null
      let that = this
      if (status == 0) {
        files = this.$refs.inputer0.files[0]
      } else if (status == 1) {
        files = this.$refs.inputer1.files[0]
      }
      let formData = new FormData()
      formData.append('cert', files)
      this.$axios.uploadFile(this.$api.upload.addCert, formData).then((res) => {
        if (res.code == 0) {
          that.$message.success('上传成功')
          if (status == 0) {
            this.$set(this.baiduPay, 'rsaPrivateKeyFilePath', res.result.path)
          } else {
            this.$set(this.baiduPay, 'rsaPublicKeyFilePath', res.result.path)
          }
        } else {
          that.$message.error(`${res.msg}`)
        }
      })
    },
    // 保存信息
    preservation() {
      let that = this
      if (!this.baiduPay.appKey || !this.baiduPay.dealId || !this.baiduPay.rsaPrivateKeyFilePath || !this.baiduPay.rsaPublicKeyFilePath) {
        this.$message({
          message: '请将配置项填写完整！！！',
          type: 'warning'
        })
        return
      }
      let sets = {
        appKey: this.baiduPay.appKey,
        dealId: this.baiduPay.dealId,
        rsaPrivateKeyFilePath: this.baiduPay.rsaPrivateKeyFilePath,
        rsaPublicKeyFilePath: this.baiduPay.rsaPublicKeyFilePath
      }
      let url = ''
      let params = {
        sets,
        type: 11
      }
      if (this.isHave) {
        url = this.$api.set.Settingedit
        params.id = this.id
      } else {
        url = this.$api.set.Settingadd
      }
      this.$axios.post(url, params).then((res) => {
        if (res.code == 0) {
          that.$emit('baiduPaySureSu')
          that.$message({
            message: '保存成功',
            type: 'success'
          })
          that.isHave = true
          return
        }
        that.$message({
          message: '保存失败',
          type: 'error'
        })
      })
    },
    // 获取信息
    getInfo() {
      let that = this
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 11
        })
        .then((res) => {
          if (res.code == 0) {
            if (res.result.info == '') {
              that.isHave = false
              return
            }
            that.id = res.result.info.id
            that.baiduPay = res.result.info.sets
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.firstBox {
  .itembox {
    display: flex;
    align-items: center;
    .title {
      flex: 0 0 200px;
      flex-shrink: 0;
    }
    .content {
      flex: 1;
      flex-shrink: 0;
      padding: 23px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      .upBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .el-input {
        width: 70%;
        line-height: normal !important;
        /deep/ input {
          line-height: normal !important;
          letter-spacing: 1px;
          border: 1px solid transparent;
          padding: 0;
          height: inherit;
        }
      }
      .uploadFile {
        position: relative;
        color: #1467ff;
        cursor: pointer;
        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
        }
      }
      .lookJc {
        color: #1467ff;
        cursor: pointer;
      }
    }
  }
}
</style>
